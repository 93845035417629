import React, { Component } from "react";
import Media from "react-media";
import { BrowserRouter as Router, NavLink, withRouter } from "react-router-dom";
import constants from "../../../constants";
import memphis_600 from "../../../img/memphis-pattern-sm.png";
import { ReactComponent as AESiFilesLogo } from "../../../svg/logo/DMSlogofilled-bluew.svg";
import { ReactComponent as Approvals } from "../../../svg/nav/check_circle.svg";
import { ReactComponent as MyDashboard } from "../../../svg/nav/dashboard.svg";
import { ReactComponent as AESiFiles } from "../../../svg/nav/Drive.svg";
import { ReactComponent as ForPublish } from "../../../svg/nav/for_publish.svg";
import { ReactComponent as Maintenance } from "../../../svg/nav/settings.svg";
import { ReactComponent as Shared } from "../../../svg/nav/share.svg";
import { ReactComponent as Starred } from "../../../svg/nav/Star-filled.svg";
import { ReactComponent as Trash } from "../../../svg/nav/trash.svg";
import { getCurrentUser } from "../../functions/users";
import CreateNew from "../CreateNew/CreateNew";
import "./Sidenav.scss";

let isCreateDisabled = false;

class Sidenav extends Component {
  render() {
    const { isDRC } = getCurrentUser();
    return (
      <div className="aesi-sidenav">
        <div className="sidenav-header">
          <img src={memphis_600} alt={constants.app_name} />
          <AESiFilesLogo />
        </div>
        <CreateNew disabledCreate={isCreateDisabled} />
        <Router component={Sidenav}>
          <ul className="sidenav-items">
            <Media
              query={constants.media_queries.sm_min}
              render={() => (
                <>
                  <NavLink
                    activeClassName="active"
                    to="/dashboard"
                    onClick={() => {
                      this.props.history.push("/dashboard");
                      localStorage.setItem(
                        "currentDirectory",
                        JSON.stringify({ id: "*", name: "root" })
                      );
                      isCreateDisabled = false;
                    }}
                  >
                    <li>
                      <MyDashboard />
                      <p>My Dashboard</p>
                    </li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/files/*"
                    onClick={() => {
                      this.props.history.push("/files/*");

                      isCreateDisabled = false;
                    }}
                  >
                    <li>
                      <AESiFiles />
                      <p>Files</p>
                    </li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/starred"
                    onClick={() => {
                      this.props.history.push("/starred");

                      isCreateDisabled = true;
                    }}
                  >
                    <li>
                      <Starred />
                      <p>Starred</p>
                    </li>
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/approvals"
                    onClick={() => {
                      this.props.history.push("/approvals");

                      isCreateDisabled = true;
                    }}
                  >
                    <li>
                      <Approvals />
                      <p>Approvals</p>
                    </li>
                  </NavLink>
                  {/* <NavLink
                    activeClassName="active"
                    to="/f/working"
                    onClick={() => {
                      this.props.history.push("/under_construction");

                      isCreateDisabled = true;
                    }}>
                    <li>
                      <Working />
                      <p>Working</p>
                    </li>
                  </NavLink> */}
                </>
              )}
            />
            {/* <NavLink
              activeClassName="active"
              to="/f/archived"
              onClick={() => {
                this.props.history.push("/under_construction");

                isCreateDisabled = true;
              }}>
              <li>
                <Archived />
                <p>Archived</p>
              </li>
            </NavLink> */}
            {/* <NavLink
              activeClassName="active"
              to="/f/recent"
              onClick={() => {
                this.props.history.push("/under_construction");

                isCreateDisabled = true;
              }}>
              <li>
                <Recent />
                <p>Recent</p>
              </li>
            </NavLink> */}
            <NavLink
              activeClassName="active"
              to="/shared"
              onClick={() => {
                this.props.history.push("/shared");
                isCreateDisabled = true;
              }}
            >
              <li>
                <Shared />
                <p>Shared</p>
              </li>
            </NavLink>
            {isDRC && (
              <>
                <NavLink
                  activeClassName="active"
                  to="/for_publish"
                  onClick={() => {
                    this.props.history.push("/for_publish");
                    isCreateDisabled = true;
                  }}
                >
                  <li>
                    <ForPublish />
                    <p>For Publish</p>
                  </li>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/trash"
                  onClick={() => {
                    this.props.history.push("/trash");
                    isCreateDisabled = true;
                  }}
                >
                  <li>
                    <Trash />
                    <p>Trash</p>
                  </li>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to="/admin/maintenance"
                  onClick={() => {
                    this.props.history.push("/admin/maintenance");

                    isCreateDisabled = true;
                  }}
                >
                  <li>
                    <Maintenance />
                    <p>Maintenance</p>
                  </li>
                </NavLink>
              </>
            )}
          </ul>
        </Router>
      </div>
    );
  }
}

export default withRouter(Sidenav);
