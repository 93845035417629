import classNames from "classnames";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import constants from "../../../constants";
import "./DocIcons.scss";

class DocIcons extends Component {
  getClassNames = () => {
    const classes = classNames({
      "aesi-docicon": true,
      "aesi-docicon-withname": this.props.withName,
      "aesi-docicon-block": this.props.block,
      "aesi-docicon-thumbnail": this.props.thumbnail,
    });
    return classes;
  };

  render() {
    const { withName, data, ext } = this.props;

    if (data && !data.name && data.objectType === "ARC") {
      data.name = data.s3Path.split("_").splice(1).join("_");
    }

    return (
      <div className={this.getClassNames()}>
        {ext in constants.doctype_icons
          ? constants.doctype_icons[ext]
          : constants.doctype_icons.docx}
        {withName && <p>{data.name} </p>}
      </div>
    );
  }
}

export default withRouter(DocIcons);
