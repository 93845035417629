import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Dropdown, Header, Loader } from "semantic-ui-react";
import Filecards from "../Filecards/Filecards";
import { IconButton as IconBtn } from "../IconButton/IconButton";
import List from "../Lists/Lists";
import "./FileView.scss";

class FileView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleCards: true,
      allFiles: this.props.data,
      dcParentId: "",
      dbClick: false,
      insideFolder: [],
      sortProperty: "name",
      sortOrder: "DESC",
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      toggleCards: !prevState.toggleCards,
    }));
  };

  componentDidMount = () => {
    const currentDirectory = localStorage.getItem("currentDirectory")
      ? JSON.parse(localStorage.getItem("currentDirectory"))
      : { id: "*", name: "root" };

    const { id, name } = currentDirectory;

    this.getParentId(id, name);
  };

  getParentId = (id, name) => {
    this.setState({
      dcParentId: id,
    });

    this.props.parentCallback(id, name);
    this.props.getFolderName(id, name);
    localStorage.setItem("currentDirectory", JSON.stringify({ id, name }));
  };

  getFoldersOnly = (files) => {
    let foldersOnly = [];

    foldersOnly = files.filter((file) => file.objectType === "DIR");

    // console.log("FOLDERS", foldersOnly);

    return foldersOnly;
  };

  getFilesOnly = (files) => {
    let filesOnly = [];

    filesOnly = files.filter(
      (file) => file.objectType === "DOC" || file.objectType === "ARC"
    );

    return filesOnly;
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  render() {
    const { toggleCards, sortProperty, sortOrder } = this.state;
    const { withQuickAccess, dataWithParent, setSort, isLoading } = this.props;
    let { data } = this.props;

    data = dataWithParent.length > 0 ? dataWithParent : data;

    const foldersList = this.getFoldersOnly(data);
    const filesList = this.getFilesOnly(data);

    // console.log("filesLIST", filesList);

    return (
      <>
        <div className="bready-tools">
          <Dropdown
            placeholder="Sort By"
            name="sortProperty"
            inline
            openOnFocus
            closeOnEscape
            compact
            options={[
              { key: "name", value: "name", text: "Name" },
              { key: "updatedAt", value: "updatedAt", text: "Updated" },
              { key: "createdAt", value: "createdAt", text: "Created" },
            ]}
            onChange={(e, { name, value }) => {
              this.setState({ [name]: value });
              setSort(value, sortOrder);
            }}
            value={sortProperty}
          />
          <IconBtn
            styled
            name={sortOrder === "DESC" ? "desc" : "asc"}
            onClick={() => {
              this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === "ASC" ? "DESC" : "ASC",
              }));
              setSort(sortProperty, sortOrder === "ASC" ? "DESC" : "ASC");
            }}
          />
          <IconBtn
            styled
            name={toggleCards ? "list" : "grid"}
            onClick={() => {
              this.handleToggle();
            }}
          />
        </div>
        <div className="aesi-scrollable-content">
          {withQuickAccess && (
            <>
              <div className="aesi-filesheader">
                <p>Quick Access</p>
              </div>
              <div id="aesi-quickaccess"></div>
            </>
          )}
          {toggleCards ? (
            <>
              {data.length > 0 ? (
                <>
                  {foldersList.length > 0 && (
                    <>
                      <div className="aesi-filesheader aesi-filesheader-withtoggle">
                        <p>Folders</p>
                      </div>
                      <Filecards
                        folders
                        data={foldersList}
                        parentCallback={this.getParentId}
                      />
                    </>
                  )}
                  <div className="aesi-filesheader aesi-filesheader-withtoggle">
                    <p>Files</p>
                  </div>

                  <Filecards
                    data={filesList}
                    parentCallback={this.getParentId}
                    removeFile={this.props.removeFile}
                  />
                </>
              ) : isLoading ? (
                <Loader inline="centered" active />
              ) : (
                this.props.noDataComponent ?? (
                  <Header as="h5">
                    No Documents. Click create button to add.
                  </Header>
                )
              )}
            </>
          ) : (
            <>
              <List data={data} />
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(FileView);
