import React from "react";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import s3Upload from "../s3/uploader";
import { createArchive } from "../documents";

let currId = "*";
let currName = "root";

export const getCurrFolderArchive = (id, name) => {
  currId = id;
  currName = name;
};

export const handleUploadArchive = async (data, filesContext) => {
  const handleSaveArchive = async (data) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const fileData = {
      objectType: "ARC", // for archive
      parentId: data.loc,
      permission: data.permission,
      tags: data.tags,
      // name: data.file[0].name, // not saved in arc
      ownerId: userData.id,
      owner: {
        name: `${userData.first_name} ${userData.last_name}`,
      },
      mimeType: data.file[0].type,
      s3Path: data.s3Path,
    };

    fileData.parentId = currId ? currId : "*";
    fileData.parentName = currName ? currName : "root";

    // console.log("ARCHIVE DATA", fileData);

    filesContext.addToDocs({ ...fileData });

    return await createArchive(fileData);
  };

  await Promise.all(
    data.file.map(async (f) => {
      const uploadResponse = await s3Upload({ ...data, file: [f] });

      if (uploadResponse.status === 204) {
        // API Call for save
        const postReponse = await handleSaveArchive({
          ...data,
          file: [f],
          s3Path: uploadResponse.s3Path,
        });
        if (postReponse) {
          toast.success(
            <>
              <div className="toast-header">Archive Upload</div>
              <div className="toast-body">Archived File Uploaded!</div>
            </>
          );
        }
      }
    })
  );

  Swal.close();
};

export default withRouter(handleUploadArchive);
