import axios from "axios";
import classNames from "classnames";
import React, { Component } from "react";
import Media from "react-media";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import constants from "../../../constants";
import { getCurrentUser } from "../../functions/users";
import { AESiModal as Modal } from "../../templates/Modal/Modal";
import DocIcon from "../DocIcons/DocIcons";
import FolderTools from "../FolderTools/FolderTools";
import Ribbon from "../Ribbon/Ribbon";
import "./Filecards.scss";

class Filecards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doubleClick: false,
      dcData: [],
      rootFolders: [],
      filesLoading: false,
      toggleFolderSettings: false,
      isArcOpen: false,
      arcData: {},
      fileExtension: "",
      s3Url: "",
    };
  }

  componentDidMount = () => {
    this.setState({
      filesLoading: true,
    });
  };

  getRibbons = (data) => {
    // Conditions for ribbons here
    const ribbons = classNames({
      working: data.checkOutDetails?.userId || data.edit_state,
      archive: data.objectType === "ARC",
      starred: false,
    });
    return ribbons;
  };

  getClasses = () => {
    const { display } = this.props;
    let filecards = classNames({
      "aesi-filecards": true,
      fixedDisplay: display,
    });

    if (display) {
      filecards += " display-" + display;
    }

    return filecards;
  };

  getFilecardClasses = () => {
    const { folders } = this.props;
    let filecard = classNames({
      "aesi-filecard": true,
      "aesi-foldercard": folders,
    });
    return filecard;
  };

  redirect = (doc) => {
    if (doc.objectType === "DIR") {
      this.setState({
        doubleClick: true,
      });
      this.props.parentCallback(doc.id, doc.name);
    } else {
      if (doc.id) {
        this.props.history.push("/doc/view/" + doc.id);
      }
    }
  };

  getRootFolders = (root) => {
    let rootData = [];

    for (let x in root) {
      if (root[x].parentId === "*") {
        rootData[x] = root[x];
      }
    }

    return rootData;
  };

  openArcModal = async (doc) => {
    let _fileExtension = doc && doc.name ? doc.name.split(".").pop() : "";

    const apiBase = process.env.REACT_APP_API_S3;
    const resp = await axios.get(
      `${apiBase}/presignedUrl?filename=${encodeURIComponent(
        doc.s3Path
      )}&method=download`,
      { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
    );
    if (resp.status === 200 && resp.data.success) {
      let encodedUrl = encodeURIComponent(resp.data.urls[0]);

      this.setState((prevState) => ({
        s3Url: encodedUrl,
        arcData: doc,
        isArcOpen: !prevState.isArcOpen,
        fileExtension: _fileExtension,
      }));
    }
  };

  close = () => {
    this.setState({
      isArcOpen: false,
    });
  };

  onError(e) {
    // logger.logError(e, "error in file-viewer");
    alert("ERR FILE VIEWER");
  }

  render() {
    let { isArcOpen, arcData, s3Url } = this.state;
    let { data, showNoDataText = true, noDataText = null } = this.props;
    const { isDRC } = getCurrentUser();

    return (
      <>
        <div className="file-card-view">
          <Media query={constants.media_queries.xs}>
            {(matches) => (
              <div className={this.getClasses()}>
                {data.length > 0 ? (
                  data.map((doc, i) => (
                    <div key={i} className={this.getFilecardClasses()}>
                      {doc.objectType === "DIR" ? (
                        <div
                          onDoubleClick={() => {
                            this.redirect(doc);
                          }}
                          style={{ position: "relative" }}
                        >
                          <Ribbon ribbons={this.getRibbons(doc)} />
                          <div className="aesi-filecard-thumbnail">
                            <DocIcon data={doc} ext={"dir"} thumbnail />
                          </div>
                          <div className="aesi-filecard-name">
                            <DocIcon data={doc} ext={"dir"} withName block />
                          </div>
                          {isDRC && (
                            <span
                              className="info-icon"
                              onDoubleClick={(e) => e.stopPropagation()}
                            >
                              <FolderTools
                                passId={doc.id}
                                passName={doc.name}
                                passFolderLoc={doc.parentName}
                                passFolderLocId={doc.parentId}
                                passCollaborators={doc.collaborators}
                              />
                            </span>
                          )}
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            doc.objectType === "DOC"
                              ? this.redirect(doc)
                              : this.openArcModal(doc)
                          }
                        >
                          <Ribbon ribbons={this.getRibbons(doc)} />
                          <div className="aesi-filecard-thumbnail">
                            <DocIcon
                              data={doc}
                              ext={
                                doc && "mimeType" in doc && doc.mimeType
                                  ? doc.mimeType
                                  : "docx"
                              }
                              thumbnail
                            />
                          </div>
                          <div className="aesi-filecard-name">
                            <DocIcon
                              data={doc}
                              ext={
                                doc && "mimeType" in doc && doc.mimeType
                                  ? doc.mimeType
                                  : "docx"
                              }
                              withName
                              block
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p className="no-data-found">
                    {" "}
                    {showNoDataText
                      ? noDataText ?? "Click create button to add."
                      : ""}
                  </p>
                )}
              </div>
            )}
          </Media>

          <Modal
            className="arc-modal"
            open={isArcOpen}
            onClose={this.close}
            size="large"
            upper
            header={
              <>
                <h2>{arcData.name}</h2>
                <span className="info-icon">
                  <FolderTools
                    noRename
                    closeParent={this.close}
                    passId={arcData.id}
                    passName={arcData.name}
                    passFolderLoc={arcData.parentName}
                    passFolderLocId={arcData.parentId}
                    objectType={arcData.objectType}
                    parentRemoveFile={this.props.removeFile}
                  />
                </span>
              </>
            }
            confirmName="Create"
            cancelAction={() => {
              this.close();
            }}
            noClose
            noActions={true}
          >
            <iframe
              title="File Preview"
              className="pdf-iframe"
              src={`https://docs.google.com/gview?url=${s3Url}&embedded=true`}
              frameBorder="0"
            ></iframe>
          </Modal>
        </div>
      </>
    );
  }
}

export class FileMinicards extends Component {
  render() {
    const { items, onClick, icon, inline } = this.props;
    return (
      <div
        className={classNames({
          "aesi-fileminicards": true,
          withClick: onClick,
          withIcon: icon,
          inline: inline,
        })}
      >
        {items.map((item, i) => (
          <div className="aesi-fileminicard" key={i} onClick={onClick}>
            <div>
              <DocIcon ext={item.extension} />
            </div>

            <div>{item.name} </div>

            {icon && <Icon name={icon} />}
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(Filecards);
