import React, { Component } from "react";
import { withRouter } from "react-router";
import { Form } from "semantic-ui-react";
import { debounce } from "lodash";
import Dropzone from "react-dropzone";
import { FileMinicards } from "../../elements/Filecards/Filecards";
import { toast } from "react-toastify";
import ChoosePrivacy from "../ChoosePrivacy/ChoosePrivacy";
import constants from "../../../constants";
import Swal from "sweetalert2";
import UploadGif from "../../../img/uploading.gif";
import { FilesContext } from "../../contexts/FilesContext";

toast.configure({
  autoClose: 5000,
  hideProgressBar: true,
  draggable: true,
  pauseOnHover: true,
});

export class FormUploadArchive extends Component {
  static contextType = FilesContext;
  constructor(props) {
    super(props);

    this.state = {
      loc: "",
      files: [],
      tags: "",
      shares: [],

      options: constants.sampleTags,
      loaderActive: false,
    };
  }

  sendData = (files) => {
    let fileData = files;
    if (files) {
      this.setState({ files });
    } else {
      fileData = this.state.files;
    }

    const data = {
      loc: this.state.loc,
      file: fileData,
      tags: this.state.tags,
      permission: this.state.shares,
    };

    this.props.checkIfFormEmpty(data);

    if (data) {
      this.props.parentCallback(data);
      this.props.getLoaderBool(true);
    }
  };

  getFolderLoc = (data) => {
    this.setState({
      loc: data,
    });

    this.sendData();
  };

  getPrivacy = (data) => {
    this.setState(
      {
        shares: data,
      },
      this.sendData
    );
  };

  handleChange = debounce((e, { name, value }) => {
    this.setState({ [name]: value });
    this.sendData();
  }, 500);

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleFiles = (files) => {
    if (files.length === 0) {
      return;
    }
    if (files) {
      Swal.fire({
        // icon: "success",
        title: "Uploading File",
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        imageUrl: UploadGif,
        imageHeight: 150,
        imageAlt: "A tall image",
      });
    }

    this.setState({ files });

    this.sendData(files);
  };

  render() {
    const { files } = this.state;
    const { reqFile } = this.props;

    return (
      <>
        <Form className="unconv">
          {/* <ChooseFolder parentCallback={this.getFolderLoc} /> */}
          {/* <Divider /> */}

          <label className={reqFile ? "required-field" : ""} htmlFor="tags">
            File for Archive*
          </label>
          <Dropzone onDrop={(acceptedFiles) => this.handleFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <section className="aesi-dropzone">
                <div {...getRootProps()}>
                  <input
                    id="aesi-drop"
                    name="feedback_file"
                    {...getInputProps()}
                  />
                  <div
                    className={
                      isDragActive
                        ? "aesi-dropzone-content dragActive"
                        : "aesi-dropzone-content"
                    }
                  >
                    {isDragActive ? (
                      <>
                        <p>Drop your file here</p>
                      </>
                    ) : (
                      <>
                        <p>
                          Drag and drop your feedback file here
                          <br />
                          or click to select the file to upload
                        </p>
                      </>
                    )}
                  </div>
                </div>
                {files.length > 0 && <FileMinicards items={files} />}
              </section>
            )}
          </Dropzone>

          <Form.Dropdown
            label="Tags"
            options={this.state.options}
            placeholder="Add Tags"
            search
            selection
            fluid
            multiple
            allowAdditions
            name="tags"
            onAddItem={this.handleAddition}
            onChange={this.handleChange}
            className="aesi-ddpadding"
          />

          <ChoosePrivacy parentCallback={this.getPrivacy} />
        </Form>
      </>
    );
  }
}

export default withRouter(FormUploadArchive);
