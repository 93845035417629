import React, { Component } from "react";
import { withRouter } from "react-router";
// import { Dropdown } from "semantic-ui-react";
import { debounce } from "lodash";
import { PrivacyView } from "../../elements/PrivacyView/PrivacyView";
import "./ChoosePrivacy.scss";

// const friendOptions = [
//   {
//     key: "Public",
//     text: "Public",
//     value: "Public",
//   },
//   {
//     key: "Group 1",
//     text: "Group 1",
//     value: "Group 1",
//   },
// ];

export class ChoosePrivacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosenPriv: "",
    };
  }

  sendData = (data) => {
    this.props.parentCallback(data);
  };

  render() {
    return <PrivacyView sendData={this.sendData} />;
  }
}

export default withRouter(ChoosePrivacy);
