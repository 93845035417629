import axios from "axios";
import { notify } from "../templates/Toast/ToastMessages";

const documentRequest = axios.create({
  baseURL: process.env.REACT_APP_API_DOCUMENTS,
});

export const getUserToken = () => {
  return localStorage.getItem("token");
};

export const createHeadersWithToken = () => {
  const token = getUserToken();
  const headers = { Authorization: `Bearer ${token}` };

  return headers;
};

const processError = (error) => {
  let response = {
    status: 500,
    message: "Unable to connect to server.",
  };
  let message = "Unable to connect to server.";
  if (error && "message" in error) message = error.message;
  if ("response" in error && error.response && "data" in error.response)
    response = error.response;
  if (typeof response.data === "object" && "message" in response.data)
    message = response.data.message;
  notify("error", "Server Error", message);
  if (response.status === 401) {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.replace("/");
  }
  return response;
};

const notifyInvalidParam = () => {
  notify("error", "Request", "Invalid Parameter");
  return;
};

export const createFolder = async (data = {}) => {
  if (!data || typeof data !== "object" || !Object.keys(data).length)
    return notifyInvalidParam();
  if (!data.name || typeof data.name !== "string" || data.name === "")
    return notifyInvalidParam();
  if (
    !data.parentId ||
    typeof data.parentId !== "string" ||
    data.parentId === ""
  )
    return notifyInvalidParam();
  if (
    !data.parentName ||
    typeof data.parentName !== "string" ||
    data.parentName === ""
  )
    return notifyInvalidParam();

  data.objectType = "DIR";

  const response = await documentRequest
    .post("/", data, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 201) {
    throw response;
  }

  return response.data;
};

export const updateFolder = async (id = "", changes = {}) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  if (!changes || typeof changes !== "object" || !Object.keys(changes).length)
    return notifyInvalidParam();

  const response = await documentRequest
    .put(
      `/update/${id}`,
      { ...changes, id },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const createArchive = async (data = {}) => {
  if (!data || typeof data !== "object" || !Object.keys(data).length)
    return notifyInvalidParam();
  if (!data.s3Path || typeof data.s3Path !== "string" || data.s3Path === "")
    return notifyInvalidParam();
  if (
    !data.parentId ||
    typeof data.parentId !== "string" ||
    data.parentId === ""
  )
    return null;
  if (
    !data.parentName ||
    typeof data.parentName !== "string" ||
    data.parentName === ""
  )
    return null;

  data.objectType = "ARC";

  const response = await documentRequest
    .post("/", data, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 201) {
    throw response;
  }

  return response.data;
};

export const createDocument = async (data = {}) => {
  if (!data || typeof data !== "object" || !Object.keys(data).length)
    return notifyInvalidParam();
  if (
    !data.parentId ||
    typeof data.parentId !== "string" ||
    data.parentId === ""
  )
    return notifyInvalidParam();
  if (
    !data.parentName ||
    typeof data.parentName !== "string" ||
    data.parentName === ""
  )
    return notifyInvalidParam();

  data.objectType = "DOC";

  const response = await documentRequest
    .post("/", data, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 201) {
    throw response;
  }

  return response.data;
};

export const getDocumentsList = async (
  parentId,
  objectTypes = [],
  sort = null
) => {
  if (!parentId || typeof parentId !== "string" || parentId === "")
    return notifyInvalidParam();
  if (!objectTypes || typeof objectTypes !== "object" || !objectTypes.length)
    return notifyInvalidParam();

  // NOTE-ES: arrays cannot be used directly in acios params
  const params = {};
  params.objectTypes = JSON.stringify(objectTypes);
  if (sort && typeof sort === "object") {
    params.sort = JSON.stringify(sort);
  } else {
    params.sort = JSON.stringify({ property: "name", order: "ASC" });
  }

  const response = await documentRequest
    .get(`/dir/${parentId}`, {
      headers: createHeadersWithToken(),
      params,
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getSharedDocumentsList = async () => {
  const flags = { sharedDocs: true };

  const response = await documentRequest
    .get(`/dir/*`, {
      headers: createHeadersWithToken(),
      params: {
        flags,
      },
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getForPublishDocumentsList = async () => {
  const flags = { forPublish: true };

  const response = await documentRequest
    .get(`/dir/*`, {
      headers: createHeadersWithToken(),
      params: {
        flags,
      },
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getAllFolders = async () => {
  const flags = { allDirectory: true };
  const objectTypes = JSON.stringify(["DIR"]);
  const response = await documentRequest
    .get(`/dir/*`, {
      headers: createHeadersWithToken(),
      params: {
        flags,
        objectTypes,
      },
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getDeleted = async () => {
  const flags = { onlyDeleted: true };
  const objectTypes = JSON.stringify(["DIR", "DOC", "ARC"]);
  const response = await documentRequest
    .get(`/dir/*`, {
      headers: createHeadersWithToken(),
      params: {
        flags,
        objectTypes,
      },
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getDocumentsForApprovals = async () => {
  const flags = { approvals: true };
  const response = await documentRequest
    .get(`/dir/*`, {
      headers: createHeadersWithToken(),
      params: {
        flags,
      },
    })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return [...response.data];
};

export const getDocument = async (id) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  const response = await documentRequest
    .get(`/info/${id}`, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const getFolder = async (id) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  const response = await documentRequest
    .get(`/info/${id}`, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const checkOutDocument = async (
  id,
  remarks,
  checkOutFlag,
  workingFilePath = null
) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  if (!remarks || typeof remarks !== "string" || remarks === "")
    return notifyInvalidParam();

  const response = await documentRequest
    .put(
      `/check_out/${id}`,
      {
        id,
        remarks,
        checkOutFlag,
        workingFilePath,
      },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const approveDocument = async (id, action = "approve", remarks) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  if (!action || typeof action !== "string" || action === "")
    return notifyInvalidParam();
  if (!remarks || typeof remarks !== "string" || remarks === "")
    return notifyInvalidParam();

  const response = await documentRequest
    .put(
      `/approve/${id}`,
      {
        id,
        action,
        remarks,
      },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const publishDocument = async (
  id,
  action = "approve",
  remarks,
  versionNumber = ""
) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  if (!action || typeof action !== "string" || action === "")
    return notifyInvalidParam();
  if (!remarks || typeof remarks !== "string" || remarks === "")
    return notifyInvalidParam();
  if (action === "approve") {
    if (
      !versionNumber ||
      typeof versionNumber !== "string" ||
      versionNumber === ""
    )
      return null;
  }

  const response = await documentRequest
    .put(
      `/publish/${id}`,
      {
        id,
        action,
        remarks,
        versionNumber,
      },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const updateDocument = async (id = "", changes = {}) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  if (!changes || typeof changes !== "object" || !Object.keys(changes).length)
    return notifyInvalidParam();

  // TODO-ES: add validations here for updatable properties

  const response = await documentRequest
    .put(
      `/update/${id}`,
      { ...changes, id },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const deleteDocument = async (id = "") => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();
  console.log("DELETE");
  const response = await documentRequest
    .delete(`/${id}`, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const restoreDocument = async (id = "") => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();

  const response = await documentRequest
    .put(`/restore/${id}`, { id }, { headers: createHeadersWithToken() })
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};

export const submitDocument = async (id) => {
  if (!id || typeof id !== "string" || id === "") return notifyInvalidParam();

  const response = await documentRequest
    .put(
      `/submit/${id}`,
      {
        id,
      },
      { headers: createHeadersWithToken() }
    )
    .catch(processError);

  if (response.status !== 200) {
    throw response;
  }

  return { ...response.data };
};
