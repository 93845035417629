import axios from "axios";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Media from "react-media";
import { Prompt, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Dimmer, Form, Loader, Table } from "semantic-ui-react";
import Swal from "sweetalert2";
import constants from "../../../../constants";
import { FileMinicards } from "../../../elements/Filecards/Filecards";
import FolderOptions from "../../../forms/FolderOptions/FolderOptions";
import { getDepartments } from "../../../functions/departments";
import {
  createDocument,
  deleteDocument,
  getDocument,
  getFolder,
  updateDocument,
} from "../../../functions/documents";
import { getDocumentTypes } from "../../../functions/document_types";
import s3Upload from "../../../functions/s3/uploader";
import { getCurrentUser, getUsers } from "../../../functions/users";
import { warn } from "../../../templates/Alert/Alert";
import { ContainerBox } from "../../../templates/Containers/Containers";
import { Col, Row } from "../../../templates/Grid/Grid";
import { AESiModal as Modal } from "../../../templates/Modal/Modal";
import { notify } from "../../../templates/Toast/ToastMessages";

// eslint-disable-next-line no-unused-vars
let loaderActive = false;

toast.configure({
  autoClose: 5000,
  hideProgressBar: true,
  draggable: true,
  pauseOnHover: true,
});

class DocForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docData: {
        tags: [],
      },
      collaboratorOptions: [],
      requiredName: false,
      isDeleted: false,
      docTypes: [
        {
          key: "",
          text: "",
          value: "",
        },
      ],
      docTypeData: [],
      folderId: window.location.pathname.split("/").pop(),
      fetchedFolderData: [],
      deleteModalOpen: false,
      deleteDocumentName: "",
      hasChanges: false,
      tagsOption: [],
      departments: [],
      departmentsData: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.id) {
      this.getData(this.props.id);
    } else {
      const { folderId: parentId } = this.props.location.state;
      let parentName = "Files";
      if (parentId && parentId !== "*") {
        const parentFolder = await getFolder(parentId);
        if (parentFolder) parentName = parentFolder.description;
      }
      const docData = {
        parentId: parentId ?? "*",
        parentName: parentName ?? "Files",
        collaborators: [],
      };
      this.setState({ docData });
    }

    this.getDocTypes();
    this.getDepartments();
    this.getCollaborators();

    // get cached tags
    let tags = localStorage.getItem("documentTags");
    if (tags) {
      tags = JSON.parse(tags);
      if (Array.isArray(tags)) {
        this.addToTags(...tags);
      }
      if (typeof tags === "object" && !Array.isArray(tags)) {
        // NOTE-ES: fix for previous data
        const tagValues = tags.map((tag) => tag.key);
        this.addToTags(...tagValues);
      }
    }
  };

  getData = async (id) => {
    const response = await getDocument(id);

    if ("tags" in response && response.tags.length) {
      const { tags } = response;
      this.addToTags(...tags);
    }

    this.setState({
      docData: response,
    });
  };

  getDocTypes = async () => {
    const docTypes = [];
    const docTypeData = await getDocumentTypes();

    for (let x in docTypeData) {
      docTypes[x] = {
        key: docTypeData[x].id,
        text: docTypeData[x].name,
        value: docTypeData[x].name,
      };
    }

    this.setState({
      docTypes,
      docTypeData,
    });
  };

  getDepartments = async () => {
    if (
      typeof this.state.departments === "object" &&
      this.state.departments.length
    )
      return;

    const departmentsData = await getDepartments();
    const departments = departmentsData.map((departmentData) => ({
      key: departmentData.id,
      value: departmentData.id,
      text: departmentData.name,
      description: departmentData.description,
    }));
    this.setState({
      departments,
      departmentsData,
    });
  };

  addToTags = (...values) => {
    const { tagsOption } = this.state;
    const tagsOptionValues = tagsOption.map((tag) => tag.key);
    values.forEach((value) => {
      if (tagsOptionValues.includes(value)) return;
      if (typeof value !== "string") return;
      tagsOption.push({ text: value, value, key: value });
      tagsOptionValues.push(value);
    });
    this.setState({ tagsOption });
    localStorage.setItem("documentTags", JSON.stringify(tagsOptionValues));
    return values;
  };

  handleChange = (e, { name, value }) => {
    const { docData } = this.state;

    if (this.props.id) {
      docData.id = this.props.id;
    }

    if (value !== docData[name] && !["collaborators"].includes(name)) {
      docData[name] = value;
    }
    docData.objectType = "DOC";

    // add approvers from dept
    if (name === "department") {
      const { departmentsData } = this.state;
      const department = departmentsData.find((dept) => dept.id === value);
      const { id, description, name } = department;
      docData.department = { id, description, name };
      docData.approvers = department.approvers;
    }

    if (name === "collaborators") {
      if (!("collaborators" in docData)) docData.collaborators = [];
      const { collaboratorsData } = this.state;
      const { collaborators } = docData;
      value.forEach((userId) => {
        if (typeof userId !== "number") return;
        if (
          collaborators.some((collaborator) => collaborator.userId === userId)
        )
          return;
        const collaborator = collaboratorsData.find(
          (collaboratorData) => collaboratorData.id === userId
        );
        if (!collaborator) return;
        docData.collaborators.push({
          userId: collaborator.id,
          description: `${collaborator.first_name} ${collaborator.last_name}`,
        });
      });
      docData.collaborators = docData.collaborators.filter((collaborator) =>
        value.includes(collaborator.userId)
      );
    }

    if (docData.name) {
      this.setState({
        requiredName: false,
      });
    }

    if (docData) {
      this.setState({
        hasChanges: true,
      });
    } else {
      this.setState({
        hasChanges: false,
      });
    }

    this.setState({ docData });
  };

  handleDeleteChange = (e, { value }) => {
    this.setState({
      deleteDocumentName: value,
    });
  };

  handleFiles = (files) => {
    let { docData } = this.state;

    if (files.length === 0) {
      return;
    } else if (files.length > 1) {
      toast.error(
        <>
          <div className="toast-header">Upload Error</div>
          <div className="toast-body">You can upload one (1) file only.</div>
        </>
      );
    } else {
      docData.file = files;

      this.setState({
        docData,
      });
    }
  };

  getPositions = async () => {
    const token = localStorage.getItem("token");
    const { id: userId } = getCurrentUser();
    const peepsRequest = axios.create({
      baseURL: process.env.REACT_APP_API_PEEPS,
      timeout: 1000,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-user-id": userId,
        Accept: "application/json",
      },
    });
    try {
      const response = await peepsRequest.get("employee_positions");
      return response.data;
    } catch (error) {
      alert("Could not retrieve employee positions.");
    }
  };

  getCollaborators = async () => {
    const collaboratorsData = await getUsers();
    const collaboratorOptions = collaboratorsData.map((user) => ({
      key: user.id,
      text: `${user.first_name} ${user.last_name}`,
      value: user.id,
      description: user.user_type_name,
    }));
    this.setState({ collaboratorOptions, collaboratorsData });
  };

  getCollaboratorIds = () => {
    const { collaborators } = this.state.docData;
    if (!collaborators || !collaborators.length) return [];

    return collaborators.map((collaborator) => collaborator.userId);
  };

  handleDelete = async () => {
    alert("HANDLE DELETE");
    const { docData, deleteDocumentName } = this.state;
    if (deleteDocumentName !== docData.name) {
      notify("error", "Delete Document", "Document name does not match!");
      return;
    }

    const response = await deleteDocument(docData.id);
    notify("success", "Delete Document", "Document deleted succesfully.");
    if (response) this.props.history.push(`/files/${docData.parentId}`);
  };

  updateParentFolder = (name, id) => {
    this.handleChange(null, { name: "parentId", value: id });
    this.handleChange(null, { name: "parentName", value: name });
  };

  handleModalTrigger = () => {
    this.setState((prevState) => ({
      deleteModalOpen: !prevState.deleteModalOpen,
    }));
  };

  closeDeleteModal = () => {
    this.setState({
      deleteModalOpen: false,
      deleteDocumentName: "",
    });
  };

  getBoolModal = (bool) => {
    this.props.closeModal(bool);
  };

  promptDiscardChanges = async () => {
    return await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, leave page.",
    });
  };

  cancelAction = async () => {
    const { id } = this.state.docData;
    const cancelModal = await this.promptDiscardChanges();
    if (cancelModal.isConfirmed) this.props.history.push(`/doc/view/${id}`);
  };

  handleFormSubmit = async (data) => {
    const isConfirmed = await warn({
      title: `Do you want to ${data.id ? "update" : "create"} this document?`,
    });
    if (!isConfirmed) return;
    if (data.file) {
      const uploadResponse = await s3Upload(data);
      data.s3Path = uploadResponse.s3Path;
    }
    let response = null;
    if (data.id) {
      const {
        approvers,
        collaborators,
        department,
        description,
        documentId,
        documentType,
        icon,
        mimeType,
        name,
        originalFileName,
        parentId,
        parentName,
        s3Path,
        size,
        tags,
      } = data;
      response = await updateDocument(data.id, {
        approvers,
        collaborators,
        department,
        description,
        documentId,
        documentType,
        icon,
        mimeType,
        name,
        originalFileName,
        parentId,
        parentName,
        s3Path,
        size,
        tags,
      });
    } else {
      response = await createDocument(data);
    }
    this.setState({ hasChanges: false });
    if (response) this.props.history.push("/doc/view/" + response.id);
  };

  render() {
    const { id } = this.props;
    const {
      docData,
      docTypes,
      departments,
      collaboratorOptions,
      deleteModalOpen,
      loaderActive,
      hasChanges,
      tagsOption,
    } = this.state;

    const ActionsXs = () => (
      <>
        <Button
          type="button"
          size="tiny"
          basic
          color="red"
          circular
          icon="times"
          onClick={() => {
            this.cancelAction();
          }}
        />
        <Button
          type="submit"
          form="documentForm"
          size="tiny"
          color="green"
          circular
          icon="check"
        />
      </>
    );
    const Actions = () => (
      <>
        <Button
          type="button"
          size="tiny"
          basic
          color="red"
          onClick={() => {
            this.cancelAction();
          }}
        >
          Cancel
        </Button>
        <Button type="submit" form="documentForm" size="tiny" color="green">
          {id ? "Save" : "Create"}
        </Button>
      </>
    );

    return (
      <>
        <Prompt
          when={hasChanges}
          message={() => "Are you sure you want to discard changes?"}
        />

        <div className="bready-tools mt-3">
          <div className="aesi-rowactions">
            <Media query={constants.media_queries.xs}>
              {(matches) => (matches ? <ActionsXs /> : <Actions />)}
            </Media>
          </div>
        </div>

        <div className="aesi-scrollable-content mt-3">
          <Dimmer active={loaderActive}>
            <Loader></Loader>
          </Dimmer>

          <Form
            id="documentForm"
            onSubmit={() => this.handleFormSubmit(docData)}
          >
            <Row>
              <Col xs="12" md={{ size: 5, offset: 1 }}>
                <ContainerBox label="Document Details">
                  <FolderOptions
                    name={docData.name ?? "Document"}
                    parentCallback={this.updateParentFolder}
                    parentName={docData.parentName}
                  />
                  <Form.Input
                    width={16}
                    label="Document ID"
                    placeholder="Document ID"
                    name="documentId"
                    onChange={this.handleChange}
                    defaultValue={docData.documentId}
                    required
                  />
                  <Form.Input
                    width={16}
                    label="Document Name"
                    placeholder="Document Name"
                    name="name"
                    onChange={this.handleChange}
                    defaultValue={docData.name}
                    required
                  />
                  <Form.TextArea
                    width={16}
                    label="Document Description"
                    placeholder="Enter description here"
                    rows={3}
                    name="description"
                    onChange={this.handleChange}
                    defaultValue={docData.description}
                  />
                  <Form.Dropdown
                    label="Tags"
                    options={tagsOption}
                    placeholder="Add Tags"
                    search
                    selection
                    fluid
                    multiple
                    allowAdditions
                    name="tags"
                    onAddItem={(e, { value }) => {
                      this.addToTags(value);
                    }}
                    onChange={this.handleChange}
                    className="aesi-ddpadding"
                    value={docData.tags || []}
                  />
                  <Form.Dropdown
                    label="Document Type"
                    options={docTypes}
                    placeholder="Document Type"
                    search
                    selection
                    fluid
                    name="documentType"
                    onChange={this.handleChange}
                    className="aesi-ddpadding"
                    value={docData.documentType}
                    required
                  />
                  <Form.Dropdown
                    label="Department"
                    options={departments}
                    placeholder="Department"
                    search
                    selection
                    fluid
                    name="department"
                    onChange={this.handleChange}
                    className="aesi-ddpadding"
                    value={docData.department?.id}
                    required
                  />
                  {!id && (
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        this.setState({
                          loaderActive: true,
                        });

                        this.handleFiles(acceptedFiles);

                        this.setState({
                          loaderActive: false,
                        });
                      }}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <section className="aesi-dropzone">
                          <div {...getRootProps()}>
                            <input
                              id="aesi-drop"
                              name="feedback_file"
                              {...getInputProps()}
                            />
                            <div
                              className={
                                isDragActive
                                  ? "aesi-dropzone-content dragActive"
                                  : "aesi-dropzone-content"
                              }
                            >
                              {isDragActive ? (
                                <>
                                  <p>Drop your file here</p>
                                </>
                              ) : (
                                <>
                                  <p>
                                    Drag and drop your file here
                                    <br />
                                    or click to select the file to upload
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                          {docData.file && docData.file.length > 0 && (
                            <FileMinicards items={docData.file} />
                          )}
                        </section>
                      )}
                    </Dropzone>
                  )}
                </ContainerBox>
              </Col>
              <Col xs="12" md={{ size: 5 }}>
                <Row xs="1">
                  <Col>
                    <ContainerBox label="Collaborators">
                      <Form.Dropdown
                        label="Select Collaborators"
                        options={collaboratorOptions}
                        placeholder="Add Collaborators"
                        search
                        selection
                        fluid
                        multiple
                        name="collaborators"
                        onChange={this.handleChange}
                        className="aesi-ddpadding"
                        clearable={true}
                        value={this.getCollaboratorIds()}
                      />
                    </ContainerBox>
                    <ContainerBox>
                      <Table basic="very">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Approver</Table.HeaderCell>
                            <Table.HeaderCell>Level</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {"approvers" in docData &&
                            docData.approvers.map((approver, index) => (
                              <Table.Row key={index}>
                                <Table.Cell>{approver.description}</Table.Cell>
                                <Table.Cell>{approver.level}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </ContainerBox>
                    {id && (
                      <>
                        <ContainerBox
                          label="Delete Document"
                          text="You can retrieve this document for 7 days upon deleting."
                          actions={
                            <>
                              <Button
                                type="button"
                                basic
                                size="tiny"
                                color="red"
                                onClick={() => {
                                  this.handleModalTrigger();
                                }}
                              >
                                Delete
                              </Button>
                            </>
                          }
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Modal
            theme="error"
            open={deleteModalOpen}
            onClose={this.closeDeleteModal}
            upper
            header={
              <h2>
                Delete <span>{docData.name}</span>
              </h2>
            }
            confirmName="Delete"
            confirmAction={this.handleDelete}
            cancelAction={() => {
              this.closeDeleteModal();
            }}
            noClose
          >
            <Form onSubmit={this.handleDelete}>
              <Form.Input
                width={16}
                name="name"
                onChange={this.handleDeleteChange}
                placeholder="Enter filenname to delete"
                defaultValue={this.state.deleteDocumentName}
                required
              />
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}

export default withRouter(DocForm);
