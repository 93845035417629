import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { debounce } from "lodash";
import React, { Component } from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2";
import { ReactComponent as DocPlus } from "../../../svg/doc/Plus.svg";
import { ReactComponent as ArchiveUp } from "../../../svg/doc/Up/Archive.svg";
import { ReactComponent as FolderPlus } from "../../../svg/folder/Plus.svg";
import { FilesContext } from "../../contexts/FilesContext";
import { handleToastMessages } from "../../elements/ToastMessages/ToastMessages";
import FormFolder from "../../forms/CreateFolder/CreateFolder";
import DocForm from "../../forms/Document/DocForm/DocForm";
import FormUploadArchive from "../../forms/Upload Archive/UploadArchive";
import { handleCreateFolder } from "../../functions/CreateFolder/CreateFolder";
import { handleUploadArchive } from "../../functions/UploadArchive/UploadArchive";
import { AESiModal as Modal } from "../../templates/Modal/Modal";
import { AESiPopup as Popup } from "../../templates/Popup/Popup";
import "./CreateNew.scss";

let _hasChanges = false;

class CreateNew extends Component {
  static contextType = FilesContext;
  constructor(props) {
    super(props);

    this.state = {
      isFolderOpen: false,
      isArchiveOpen: false,
      isDocumentOpen: false,
      folderData: {},
      archiveData: {},
      folderName: "",
      disableCreate: false,
      loaderActive: false,
      pDocData: {},
      hasChanges: false,
      reqField: {
        folderName: false,
        fileUpload: false,
      },
    };
  }

  getFolderData = (data) => {
    this.setState({
      folderData: data,
    });
  };

  getArchiveData = (data) => {
    this.setState({
      archiveData: data,
    });
  };

  handleModalTrigger = (which) => {
    let { reqField } = this.state;

    reqField = {
      folderName: false,
      fileUpload: false,
    };

    this.setState({
      reqField,
    });

    if (which === "folder") {
      this.setState((prevState) => ({
        isFolderOpen: !prevState.isFolderOpen,
      }));
    } else if (which === "archive") {
      this.setState((prevState) => ({
        isArchiveOpen: !prevState.isArchiveOpen,
      }));
    } else if (which === "document") {
      this.setState((prevState) => ({
        isDocumentOpen: !prevState.isDocumentOpen,
      }));
    }
  };

  checkRequired = (which, data, filesContext) => {
    let { reqField } = this.state;

    if (which === "folder") {
      if (!data.name) {
        reqField.folderName = true;

        this.setState({
          reqField,
        });

        handleToastMessages(
          "error",
          "Required Field!",
          "Folder name is required!"
        );
      } else {
        this.setState((prevState) => ({
          isFolderOpen: !prevState.isFolderOpen,
        }));

        handleCreateFolder(data, filesContext);
      }
    } else if (which === "archive") {
      // console.log("DADTATDTADA", data);

      if (!data.file) {
        // console.log("WENT HEERE");
        reqField.fileUpload = true;

        this.setState({
          reqField,
        });

        handleToastMessages("error", "File Required!", "Please upload file!");
      } else {
        this.setState((prevState) => ({
          isArchiveOpen: !prevState.isArchiveOpen,
        }));

        console.log(
          "sending data to handle upload archive:",
          data,
          FilesContext
        );

        handleUploadArchive(data, filesContext);
      }
    }
  };

  close = () => {
    if (_hasChanges) {
      Swal.fire({
        title: "Discard changes?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
        customClass: {
          container: "save-changes-swal",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          _hasChanges = false;

          this.setState({
            isDocumentOpen: false,
            isFolderOpen: false,
            isArchiveOpen: false,
          });
        }
      });
    } else {
      _hasChanges = false;

      this.setState({
        isDocumentOpen: false,
        isFolderOpen: false,
        isArchiveOpen: false,
      });
    }
  };

  checkIfFormEmpty = (data) => {
    for (let x in data) {
      if (data[x] !== null && data[x] !== "") {
        _hasChanges = true;
      }
    }

    // console.log("DATA", data);
  };

  getLoaderBool = (data) => {
    this.setState({
      loaderActive: data,
    });
  };

  closeThisModal = (bool) => {
    this.setState({
      isDocumentOpen: bool,
    });
  };

  render() {
    const { isFolderOpen, isArchiveOpen, isDocumentOpen, loaderActive } =
      this.state;
    const { context: filesContext } = this;

    return (
      <div className="aesi-createnew">
        <Popup
          type="create new"
          trigger={
            <Button
              className={classnames({
                "aesi-btn aesi-primary onwhite": true,
                pen: this.props.disabledCreate,
              })}
            >
              <FontAwesomeIcon icon={faPlus} />
              Create New
            </Button>
          }
        >
          <ul>
            <li
              onClick={() => {
                this.handleModalTrigger("folder");
              }}
            >
              <FolderPlus />
              <div>Create Folder</div>
            </li>
            <Router>
              <li
                onClick={() => {
                  this.props.history.push("/doc/create", {
                    folderId: this.context.parentId,
                  });
                }}
              >
                <DocPlus />
                <div>Create Document</div>
              </li>
            </Router>
            <li
              onClick={() => {
                this.handleModalTrigger("archive");
              }}
            >
              <ArchiveUp />
              <div>Upload Archive</div>
            </li>
          </ul>
        </Popup>

        {/* Create Document Modal */}
        {/* <Dimmer active={loaderActive}>
          <Loader indeterminate>Uploading File</Loader>
        </Dimmer> */}

        <Modal
          className="doc-modal"
          open={isDocumentOpen}
          onClose={this.close}
          size="large"
          upper
          header={<h2>Create Document</h2>}
          confirmName="Create"
          confirmAction={debounce(() => {
            this.handleModalTrigger("document");
            // handleCreateFolder(this.state.folderData, filesContext);
          }, 1000)}
          cancelAction={() => {
            this.close();
          }}
          noClose
          noActions={true}
        >
          {/* <FormFolder parentCallback={this.getFolderData} /> */}
          <DocForm
            checkIfFormEmpty={this.checkIfFormEmpty}
            closeModal={this.closeThisModal}
          />
        </Modal>

        {/* Create Folder Modal */}
        <Modal
          open={isFolderOpen}
          onClose={this.close}
          upper
          header={<h2>Create Folder</h2>}
          confirmName="Create"
          confirmAction={() => {
            this.setState({
              loaderActive: true,
            });

            // this.handleModalTrigger("folder");
            this.checkRequired("folder", this.state.folderData, filesContext);

            this.setState({
              loaderActive: false,
            });
          }}
          cancelAction={() => {
            this.close();
          }}
          noClose
        >
          <FormFolder
            stopLoader={loaderActive}
            parentCallback={this.getFolderData}
            checkIfFormEmpty={this.checkIfFormEmpty}
            reqName={this.state.reqField.folderName}
          />
        </Modal>

        {/* Upload Archive Modal */}
        <Modal
          open={isArchiveOpen}
          onClose={this.close}
          upper
          header={<h2>Upload Archived File</h2>}
          confirmName="Upload"
          confirmAction={debounce(() => {
            // this.handleModalTrigger("archive");

            this.checkRequired("archive", this.state.archiveData, filesContext);
          }, 1000)}
          cancelAction={() => {
            this.close();
          }}
          noClose
        >
          <FormUploadArchive
            parentCallback={this.getArchiveData}
            checkIfFormEmpty={this.checkIfFormEmpty}
            getLoaderBool={this.getLoaderBool}
            reqFile={this.state.reqField.fileUpload}
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(CreateNew);
