import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Dimmer, Form, Icon, Loader, Popup } from "semantic-ui-react";
import { FilesContext } from "../../contexts/FilesContext";
import { handleToastMessages } from "../../elements/ToastMessages/ToastMessages";
import FolderOptions from "../../forms/FolderOptions/FolderOptions";
import { getAllFolders, updateDocument } from "../../functions/documents";
import { handleUpdateDocument } from "../../functions/UpdateDocument/UpdateDocument";
import { getUsers } from "../../functions/users";
import { warn } from "../../templates/Alert/Alert";
import { AESiModal as Modal } from "../../templates/Modal/Modal";
import { notify } from "../../templates/Toast/ToastMessages";
import "./FolderTools.scss";

let thisMode = "";

class FolderTools extends Component {
  static contextType = FilesContext;

  constructor(props) {
    super(props);

    this.state = {
      isFolderSettingsOpen: false,
      modalMode: "",
      folderData: {},
      passedFolderLoc: this.props.passFolderLoc,
      hasSpecial: false,
      fetchedDir: [],
      checkName: "",
      notDuplicate: false,
      isDuplicate: false,
      deleteFolderName: "",
      noDeleteName: false,
      updateCancel: false,
      moveFolderId: "",
      moveFolderLoc: "",
      loaderActive: false,
      collaboratorOptions: [],
      collaboratorsData: [],
    };
  }

  componentDidMount = () => {
    const { context: filesContext } = this;

    filesContext.toggleModal(false);
  };

  close = () => {
    this.props.closeParent();
    this.setState({
      isFolderSettingsOpen: false,
      updateCancel: true,
    });
  };

  folderSettings = (i, mode) => {
    const { context: filesContext } = this;
    const { folderData } = this.state;
    if ("passCollaborators" in this.props) {
      folderData.collaborators = this.props.passCollaborators;
    }
    this.setState({ folderData });

    filesContext.toggleModal(false);

    if (mode) {
      this.setState({
        isFolderSettingsOpen: true,
      });
      if (mode === "collaborators") {
        this.getCollaboratorOptions();
      }
    }

    this.setState({
      modalMode: mode,
    });

    thisMode = mode;
  };

  getCollaboratorOptions = async () => {
    if (this.state.collaboratorOptions.length) return;
    const collaboratorsData = await getUsers();
    const collaboratorOptions = collaboratorsData.map((user) => ({
      key: user.id,
      text: `${user.first_name} ${user.last_name}`,
      value: user.id,
      description: user.user_type_name,
    }));
    this.setState({ collaboratorsData, collaboratorOptions });
  };

  getCollaboratorIds = (collaborators = []) => {
    if (!collaborators || !collaborators.length) return [];
    return collaborators.map((collaborator) => collaborator.userId);
  };

  handleChange = (e, { name, value }) => {
    let { folderData, hasSpecial, deleteFolderName } = this.state;

    folderData[name] = value;

    if (folderData.name) {
      // hasSpecial = !/^[a-zA-Z0-9-_ ]+$/.test(folderData.name) ? true : false;

      deleteFolderName = folderData.name;

      this.setState({
        noDeleteName: false,
      });
    }

    if (name === "collaborators") {
      const { collaboratorsData } = this.state;
      const { collaborators } = folderData;
      value.forEach((userId) => {
        if (typeof userId !== "number") return;
        if (
          collaborators.some((collaborator) => collaborator.userId === userId)
        )
          return;
        const collaborator = collaboratorsData.find(
          (collaboratorData) => collaboratorData.id === userId
        );
        if (!collaborator) return;
        folderData.collaborators.push({
          userId: collaborator.id,
          description: `${collaborator.first_name} ${collaborator.last_name}`,
        });
      });
      folderData.collaborators = folderData.collaborators.filter(
        (collaborator) => value.includes(collaborator.userId)
      );
    }

    this.setState({ folderData, hasSpecial, deleteFolderName });
  };

  handleModalTrigger = async (mode, isFolder) => {
    let { folderData, modalMode, notDuplicate, updateCancel } = this.state;
    modalMode = mode;

    folderData.id = this.props.passId;
    folderData.oldName = this.props.passName;
    folderData.name = folderData.name ? folderData.name : this.props.passName;

    this.setState({
      folderData,
      modalMode,
      // loaderActive: true,
    });

    if (thisMode === "rename") {
      if (notDuplicate) {
        let hasSpecial = !/^[a-zA-Z0-9-_. ]+$/.test(folderData.name)
          ? true
          : false;

        if (hasSpecial) {
          handleToastMessages(
            "error",
            "Special Characters",
            "Only '_' and '-' are allowed."
          );
        } else {
          handleUpdateDocument(folderData, thisMode, thisMode, isFolder);

          this.setState({
            loaderActive: false,
          });
        }
      } else {
        this.setState({
          checkName: folderData.name,
        });

        this.getFolders();
      }
    }

    if (thisMode === "move") {
      const isConfirmed = await warn({
        title: `Do you want to move this folder?`,
      });
      if (!isConfirmed) return;
      const { parentId, parentName } = folderData;
      const response = await updateDocument(folderData.id, {
        parentId,
        parentName,
      });
      this.setState({
        loaderActive: false,
      });
      if (response) {
        notify("success", "Move Folder", "Moved folder successfully.");
        this.close();
        if (parentId !== this.context.parentId) {
          this.context.removeFromDocs(folderData.id);
        }
      }
    }

    if (thisMode === "collaborators") {
      const { id, collaborators } = folderData;
      const isConfirmed = await warn({
        title:
          "Are you sure you want to update collaborators of this document?",
      });
      if (isConfirmed) {
        const response = await updateDocument(id, { collaborators });
        if (response) {
          this.context.updateFromDocs(id, {
            collaborators: response.collaborators,
          });
          notify(
            "success",
            "Update Collaborators",
            "Updated collaborators successfully."
          );
          this.setState({
            loaderActive: false,
          });
          this.close();
        }
      }
    }

    if (thisMode === "delete") {
      const isFolder = !this.props.objectType === "ARC";
      const type = isFolder ? "Folder" : "File";
      // folderData.name = this.state.deleteFolderName;

      this.setState({
        isFolder,
        folderData,
      });

      if (this.state.deleteFolderName) {
        if (folderData.name === this.props.passName) {
          // console.log("EYEYEYEY!");
          folderData.id = this.props.passId;
          this.setState({
            folderData,
          });

          await handleUpdateDocument(
            folderData,
            thisMode,
            thisMode,
            isFolder,
            updateCancel,
            this.props.passFolderLocId
          );
          this.props.parentRemoveFile(this.props.passId);
          this.close();
        } else {
          handleToastMessages(
            "error",
            `Delete ${type}`,
            `${type} name does not match!`
          );
        }
      } else {
        handleToastMessages(
          "error",
          `Delete ${type}`,
          `Please input ${type} name.`
        );
      }

      this.setState({
        loaderActive: false,
      });
    }
  };

  getFolders = async (data) => {
    try {
      const response = await getAllFolders();
      this.checkDuplicate(response);
    } catch (e) {}
  };

  checkDuplicate = async (data) => {
    let duplicateData = [];

    duplicateData = data.filter(
      (file) => file.name === this.state.checkName && file.deletedAt === null
    );

    // console.log("TESTSTTDS", duplicateData);

    if (duplicateData.length !== 0) {
      this.setState({
        notDuplicate: false,
      });

      handleToastMessages(
        "error",
        "Folder Duplicate",
        <span>{this.state.checkName} already exists.</span>
      );
    } else {
      this.setState({
        notDuplicate: true,
      });

      this.handleModalTrigger();
    }

    // console.log("TESTSTTDS", this.state.notDuplicate);
  };

  getDocLoc = (id, name) => {
    let { passedFolderLoc, folderData } = this.state;
    // console.log("CF LOC", id, name);

    if (name !== passedFolderLoc) {
      passedFolderLoc = name;
    }

    folderData.id = this.props.passId;
    folderData.name = this.props.passName;
    folderData.parentId = id;
    folderData.parentName = name;

    thisMode = "move";

    this.setState({ passedFolderLoc, folderData, modalMode: "move" });
  };

  updateParentFolder = (folder, id) => {
    let { folderData } = this.state;

    folderData.parentId = id;
    folderData.parentName = folder;

    this.setState({
      folderData,
    });
  };

  render() {
    const { passId, passName, passFolderLoc, noRename } = this.props;
    const {
      isFolderSettingsOpen,
      hasSpecial,
      loaderActive,
      collaboratorOptions,
      folderData,
    } = this.state;
    const confirmData = {
      rename: {
        name: "Rename",
        action: () =>
          this.handleModalTrigger("", !this.props.objectType === "ARC"),
      },
      move: {
        name: "Moves",
        action: () =>
          this.handleModalTrigger("", !this.props.objectType === "ARC"),
      },
      delete: {
        name: "Delete",
        action: () =>
          this.handleModalTrigger("", !this.props.objectType === "ARC"),
      },
      collaborators: {
        name: "Update",
        action: () =>
          this.handleModalTrigger("", !this.props.objectType === "ARC"),
      },
    };

    return (
      <>
        <Popup
          wide
          trigger={<Icon style={{ height: "100%" }} name="ellipsis vertical" />}
          on="click"
          position="bottom left"
          className="folder-tools-popup"
        >
          <ul>
            {!noRename ? (
              <li
                onClick={() => {
                  this.folderSettings(passId, "rename");
                  // this.setState({
                  //   modalMode: "rename",
                  // });
                }}
              >
                <Icon name="edit" /> Rename
              </li>
            ) : (
              ""
            )}
            <li onClick={() => this.folderSettings(passId, "move")}>
              <Icon name="folder" /> Move
            </li>
            <li onClick={() => this.folderSettings(passId, "delete")}>
              <Icon name="trash" /> Delete
            </li>
            <li onClick={() => this.folderSettings(passId, "collaborators")}>
              <Icon name="users" /> Collaborators
            </li>
          </ul>
        </Popup>

        <Modal
          open={
            this.context.isModalOpen === true ? false : isFolderSettingsOpen
          }
          onClose={this.close}
          upper
          header={
            <h2>
              {`${confirmData[thisMode]?.name} `}
              <span>{passName}</span>
            </h2>
          }
          confirmName={confirmData[thisMode]?.name}
          confirmAction={() => confirmData[thisMode]?.action()}
          cancelAction={() => {
            this.close();

            this.setState({
              updateCancel: true,
            });
          }}
          noClose
        >
          {/* Test {thisMode.charAt(0).toUpperCase() + thisMode.slice(1)} */}
          <Dimmer active={loaderActive}>
            <Loader indeterminate></Loader>
          </Dimmer>

          {thisMode === "rename" ? (
            <Form>
              <Form.Input
                width={16}
                name="name"
                onChange={this.handleChange}
                defaultValue={passName}
                className={hasSpecial ? "required-field" : ""}
              />
            </Form>
          ) : thisMode === "move" ? (
            <Form>
              <FolderOptions
                updateParentFolder={this.updateParentFolder}
                moveFolderMode={true}
                passFolder={passFolderLoc}
                passFolderName={passName}
                passFolderId={passId}
              />
            </Form>
          ) : thisMode === "delete" ? (
            <>
              <Form>
                <Form.Input
                  width={16}
                  name="name"
                  onChange={this.handleChange}
                  placeholder={
                    "Enter " +
                    (this.props.objectType === "ARC" ? "file" : "folder") +
                    " name to delete"
                  }
                />
              </Form>
            </>
          ) : thisMode === "collaborators" ? (
            <>
              <Form>
                <Form.Dropdown
                  options={collaboratorOptions}
                  label="Select Collaborators"
                  search
                  selection
                  fluid
                  multiple
                  name="collaborators"
                  clearable={true}
                  value={this.getCollaboratorIds(folderData.collaborators)}
                  onChange={this.handleChange}
                />
              </Form>
            </>
          ) : (
            ""
          )}
        </Modal>
      </>
    );
  }
}

export default withRouter(FolderTools);
