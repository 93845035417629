import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FilesContext } from "../../contexts/FilesContext";
import {
  Breadcrumbs,
  BreadyTools,
} from "../../elements/Breadcrumbs/Breadcrumbs";
import FileView from "../../elements/FileView/FileView";
import { setCurrFolder } from "../../functions/CreateFolder/CreateFolder";
import { getDocument, getDocumentsList } from "../../functions/documents";
import { getCurrFolderArchive } from "../../functions/UploadArchive/UploadArchive";

class Files extends Component {
  static contextType = FilesContext;
  constructor(props) {
    super(props);
    this.state = {
      parentId: "",
      test: false,
      filesWithParent: [],
      fileParentId: "",
      breadCrumbs: [],
      passedFolder: "",
      crumbsArr: [],
      loaderActive: false,
      testPass: "",
      showCreateFolder: false,
      getId: "",
      getName: "",
      isDefaultCrumb: false,
    };
  }

  componentDidMount = () => {
    this.getFiles();
  };

  componentDidUpdate = async () => {
    const { parentId } = this.props.match.params;
    const { parentId: savedParentId } = this.state;
    let { breadCrumbs } = this.state;

    if (parentId !== savedParentId) {
      let clearCrumbs = false;
      if (this.props.location?.state?.clearCrumbs)
        clearCrumbs = this.props.location.state.clearCrumbs;
      await this.setState({
        parentId: parentId,
      });
      let parentName = "*";
      if ((!savedParentId || clearCrumbs) && parentId !== "*") {
        const parentFolder = await getDocument(parentId);
        if (parentFolder) parentName = parentFolder.description;
      }
      if (parentId === "*" || clearCrumbs) {
        breadCrumbs = [];
        if (parentId === "*") parentName = "Files";
      }
      if (!breadCrumbs.length) {
        if (parentId !== "*") {
          breadCrumbs.push({ id: "*", name: "Files..." });
        }
        breadCrumbs.push({
          id: parentId,
          name: parentId === "*" ? "Files" : parentName,
        });
      }

      this.setState({ breadCrumbs });
      this.context.setParentInfo({ parentId });
      if (savedParentId) this.getFiles();
    }
  };

  getFiles = async (
    parentId = this.props.match.params.parentId,
    sort = null
  ) => {
    let fetchedData = [];

    this.setState({
      loaderActive: true,
    });

    try {
      if (sort === null || sort === "name") sort = { name: 1, s3Path: 1 };
      fetchedData = await getDocumentsList(
        parentId,
        ["DIR", "DOC", "ARC"],
        sort
      );
      if (fetchedData)
        fetchedData = fetchedData.filter((file) => file.deletedAt === null);

      this.setState({
        loaderActive: false,
      });
      this.context.setDocs(fetchedData);
    } catch (e) {
      if ("message" in e && e.message) alert(e.message);

      this.setState({
        loaderActive: false,
      });
    }
  };

  setSort = async (property, order) => {
    if (!property || typeof property !== "string") return;
    if (!order || !["ASC", "DESC"].includes(order)) return;
    console.log("PROPS:", property, "order:", order);
    const sortOrder = order === "ASC" ? 1 : -1;
    const sort = {};
    sort[property] = sortOrder;
    if (property === "name") {
      sort.s3Path = sortOrder;
    }
    const { parentId } = this.state;
    this.getFiles(parentId, sort);
  };

  getPid = async (id, name, crumb) => {
    const { context: filesContext } = this;

    this.props.history.push(`/files/${id}`);

    filesContext.toggleModal(true);

    this.setState({
      getId: id,
      getName: name,
    });

    setCurrFolder(id, name);

    getCurrFolderArchive(id, name);
    this.context.setParentInfo({ parentId: id, parentName: name });
  };

  getCrumbs = (id, name) => {
    let { breadCrumbs } = this.state;

    let currCrumb = breadCrumbs;
    let newCrumb = {
      id: id,
      name: name,
    };

    currCrumb.push(newCrumb);

    this.setState({
      breadCrumbs: [...currCrumb],
    });
  };

  removeFile = (fileId) => {
    const { context: filesContext } = this;
    const data = this.context.docs;
    const newData = data.filter((f) => {
      return f.id !== fileId;
    });
    filesContext.setDocs(newData);
  };

  resetCrumb = (id, index) => {
    const { breadCrumbs } = this.state;
    let currCrumb = breadCrumbs;

    currCrumb.length = index + 1;

    if (currCrumb.length === index + 1) {
      this.props.history.push(`/files/${id}`);
    }

    this.setState({
      breadCrumbs: currCrumb,
    });
  };

  render() {
    return (
      <div className="aesi-scrollable-content-container">
        <BreadyTools sendPath={this.state.breadCrumbs}>
          <Breadcrumbs
            path={this.state.breadCrumbs}
            parentCallback={this.getPid}
            resetCrumb={this.resetCrumb}
          />
          <FileView
            data={this.context.docs}
            parentCallback={this.getPid}
            getFolderName={this.getCrumbs}
            setSort={this.setSort}
            dataWithParent={this.state.filesWithParent}
            isLoading={this.state.loaderActive}
            removeFile={this.removeFile}
          />
        </BreadyTools>
      </div>
    );
  }
}

export default withRouter(Files);
